@tailwind base;
@tailwind components;
@tailwind utilities;

.image_style {
  box-shadow: 6px 6px;
  border-style: solid;
  border-width: 2px;
  border-color: black;
}

.big_image_style {
  box-shadow: 8px 8px;
  border-style: solid;
  border-width: 4px;
  border-color: black;
}

.cls-1 {
  fill: rgb(254, 254, 254);
}

.cls-2 {
  cursor: pointer;
  fill: rgb(254, 200, 118);
}

.cls-3 {
  fill: rgb(32, 22, 11);
}

.cls-2:hover {
  fill: rgb(255, 166, 49);
}
